/* eslint-disable no-script-url */
import store from '@/store';

export interface NavItem {
  name: string;
  path?: string;
  action?: () => void;
  condition?: () => boolean;
}

export const MAIN_NAV_ITEMS = (router: any): NavItem[] => [
  {
    name: 'Home',
    path: '/',
    condition: () => !store.getters.isAuthenticated,
  },
  {
    name: 'Pricing',
    path: '/pricing',
    condition: () => !store.getters.isAuthenticated,
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    condition: () => store.getters.isAuthenticated,
  },
  {
    name: 'Keywords',
    path: '/keywords',
    condition: () => store.getters.isAuthenticated,
  },
  {
    name: 'Content Calendar',
    path: '/content',
    condition: () => store.getters.isAuthenticated,
  },
  // {
  //   name: 'Admin Dashboard',
  //   path: '/admin',
  //   condition: () => store.getters.isAdmin,
  // },
  {
    name: 'Sign in',
    path: '/login',
    condition: () => !store.getters.isAuthenticated,
  },
  {
    name: 'Sign Out',
    action: async () => {
      await store.dispatch('logOut');
      router.push({ name: 'Login' });
    },
    condition: () => store.getters.isAuthenticated,
  },
];
