import { RouteRecordRaw } from 'vue-router';

const userRoutes: RouteRecordRaw[] = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/DashboardView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: () => import(/* webpackChunkName: "onboarding" */ '@/views/OnboardingView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/keywords',
    name: 'Keywords',
    component: () => import(/* webpackChunkName: "keywords" */ '@/views/KeywordsView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/sprints',
    name: 'Sprints',
    component: () => import(/* webpackChunkName: "sprints" */ '@/views/SprintsView.vue'),
  },
  {
    path: '/sprints/:id',
    name: 'SprintCalendar',
    component: () => import(/* webpackChunkName: "sprint-calendar" */ '@/views/CalendarView.vue'),
  },
  {
    path: '/content',
    name: 'Calendar',
    component: () => import(/* webpackChunkName: "calendar" */ '@/views/CalendarView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/content-brief/:id',
    name: 'ContentBrief',
    component: () => import(/* webpackChunkName: "calendar" */ '@/views/ContentBrief.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
];

export default userRoutes;
