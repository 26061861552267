// src/firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyACRrW078QR3gsXX5wx2Sk0m3vTsnQhiSw',
  authDomain: 'growthbank-v3.firebaseapp.com',
  projectId: 'growthbank-v3',
  storageBucket: 'growthbank-v3.appspot.com',
  messagingSenderId: '677215718524',
  appId: '1:677215718524:web:dbe77a23353eb5120ecdf9',
  measurementId: 'G-8CXJJM94SJ',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);

if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}
