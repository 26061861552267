
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import store from '@/store';
import MainMenu from '@/components/MainMenu.vue';

export default defineComponent({
  name: 'App',
  components: {
    MainMenu,
  },
  setup() {
    const route = useRoute();
    const showAuthenticatedHeader = computed(() => {
      const hiddenRoutes = ['Login', 'Register', 'ForgotPassword'];
      return !hiddenRoutes.includes(route.name as string);
    });

    return {
      showAuthenticatedHeader,
    };
  },
});
