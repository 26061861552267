
import { defineComponent, computed, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { MAIN_NAV_ITEMS, NavItem } from '@/constants/navigation';

export default defineComponent({
  name: 'MainMenu',
  props: {
    navType: {
      type: String as PropType<string>,
      default: 'basic',
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const navItems = computed(() => {
      switch (props.navType) {
        case 'main':
          return MAIN_NAV_ITEMS(router);
        default:
          return MAIN_NAV_ITEMS(router);
      }
    });

    const filteredNavItems = computed(() => navItems.value.filter((item) => (item.condition ? item.condition() : true)));

    const handleClick = (item: NavItem) => {
      console.log('handleClick called for item:', item);
      if (item.action) {
        item.action();
      }
    };

    return {
      navItems,
      filteredNavItems,
      handleClick,
    };
  },
});
