import { RouteRecordRaw } from 'vue-router';
import { defineAsyncComponent } from 'vue';

const LoginView = defineAsyncComponent(() => import('@/views/auth/LoginView.vue'));
const RegisterView = defineAsyncComponent(() => import('@/views/auth/RegisterView.vue'));
const ForgotPasswordView = defineAsyncComponent(() => import('@/views/auth/ForgotView.vue'));

const sharedRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/HomeView.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordView,
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import(/* webpackChunkName: "pricing" */ '@/views/PricingView.vue'),
  },
];

export default sharedRoutes;
