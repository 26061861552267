/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { app, functions } from '@/firebaseConfig';
import {
  createCheckoutSession,
  getProducts,
  getStripePayments,
  getCurrentUserSubscriptions,
} from '@stripe/firestore-stripe-payments';
import { httpsCallable } from 'firebase/functions';

export const payments = getStripePayments(app, {
  productsCollection: 'products',
  customersCollection: 'users',
});

export const retrieveProducts = async () => getProducts(payments, {
  includePrices: true,
  activeOnly: true,
});

export const checkout = async (priceId: string, name: string) => createCheckoutSession(payments, {
  price: priceId,
  trial_from_plan: true,
  allow_promotion_codes: true,
  success_url: 'https://growthbank-v3.web.app/onboarding',
  cancel_url: 'https://growthbank-v3.web.app/pricing',
});

export const manage = async () => {
  const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
  const { data } = await functionRef({
    returnUrl: window.location.origin,
  });
  return data;
};

export const retrieveSubscriptions = async () => getCurrentUserSubscriptions(payments);
