import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_3 = { class: "flex space-x-4" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredNavItems, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item.path,
            class: "py-4 text-sm font-semibold"
          }, [
            (item.path && (item.condition ? item.condition() : true))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: item.path,
                  class: "router-link"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : _createCommentVNode("", true),
            (item.action)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  onClick: ($event: any) => (item.action && item.action()),
                  href: "javascript:void(0)",
                  class: "router-link"
                }, _toDisplayString(item.name), 9, _hoisted_4))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ]))
}