import {
  doc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { User as FirebaseAuthUser, getIdTokenResult } from 'firebase/auth';
import { auth, db } from '@/firebaseConfig';
import { createStore } from 'vuex';
import { useRouter } from 'vue-router';
import { retrieveProducts, retrieveSubscriptions, manage } from '@/utils/StripeService';

const router = useRouter();

export interface UserData {
  'id': string,
  'billingAddress': object,
  'email': string,
  'firstName': string,
  'fullName': string,
  'initials': string,
  'lastName': string,
  'companyName': string,
  'industry': string,
  'niche': string,
  'targetMarket': string,
  'completedOnboarding': Date
}
export interface Content {
  'id': string,
  'assignees': Array<string>,
  'contentType': string,
  'draftDueDate': string,
  'htmlContent': string,
  'keyword': string,
  'keywordId': string,
  'liveDate': string,
  'outline': object,
  'owner': string,
  'status': string,
  'topic': string,
  'url': string
}
export interface Keyword {
  'id': string
  'competition': number,
  'cpc': number,
  'keyword': string,
  'searchVolume': number
}

const getDefaultState = () => ({
  isAuthenticated: false,
  isAdmin: false,
  user: null as FirebaseAuthUser | null,
  userData: null as UserData | null,
  keywords: [] as Keyword[],
  sprints: [],
  documents: [] as Content[],
  products: [],
  portalLink: '',
  subscriptions: [],
});

const store = createStore({
  state: getDefaultState(),
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    currentUser(state) {
      return state.user;
    },
    currentUserData(state) {
      return state.userData;
    },
    currentKeywords(state) {
      return state.keywords;
    },
    currentSprints(state) {
      return state.sprints;
    },
    currentDocuments(state) {
      return state.documents;
    },
    currentProducts(state) {
      return state.products;
    },
    currentSubscriptions(state) {
      return state.subscriptions;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setIsAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    setUserData(state, userData) {
      state.userData = userData;
    },
    setKeywords(state, keywords) {
      state.keywords = keywords;
    },
    setSprints(state, sprints) {
      state.sprints = sprints;
    },
    setDocuments(state, documents) {
      state.documents = documents;
    },
    updateDocumentStatus(state, { id, status }) {
      const document = state.documents.find((documentDoc) => documentDoc.id === id);
      if (document) {
        document.status = status;
      }
    },
    updateDocument(state, { id, payload }) {
      const document = state.documents.find((documentDoc) => documentDoc.id === id) as Content;
      if (document) {
        Object.assign(document, payload);
      }
    },
    setProducts(state, products) {
      state.products = products;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    setSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
  },
  actions: {
    async fetchUser({ commit }, user) {
      commit('setUser', user);
      if (user) {
        commit('setIsAuthenticated', true);
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          commit('setUserData', userDocSnap.data());
        }

        const userToken = await getIdTokenResult(user);
        commit('setIsAdmin', userToken.claims.admin);
      } else {
        commit('setUserData', null);
        commit('setIsAdmin', false);
      }
    },
    async fetchKeywords({ commit }, user) {
      if (user) {
        const keywordsCollection = collection(db, 'users', user.uid, 'keywords');
        const keywordsSnapshot = await getDocs(keywordsCollection);
        const keywords = keywordsSnapshot.docs.map((keywordDoc) => ({ id: keywordDoc.id, ...keywordDoc.data() }));
        commit('setKeywords', keywords);
      } else {
        commit('setKeywords', []);
      }
    },
    async fetchSprints({ commit }, user) {
      if (user) {
        const sprintsCollection = collection(db, 'users', user.uid, 'sprints');
        const sprintsSnapshot = await getDocs(sprintsCollection);
        const sprints = sprintsSnapshot.docs.map((sprintDoc) => ({ id: sprintDoc.id, ...sprintDoc.data() }));
        commit('setSprints', sprints);
      } else {
        commit('setSprints', []);
      }
    },
    async fetchDocuments({ commit }, user) {
      // TODO: ADD SPRINT SUBCOLLECTION
      if (user) {
        const documentsCollection = collection(db, 'users', user.uid, 'content');
        const documentsSnapshot = await getDocs(documentsCollection);
        const documents = documentsSnapshot.docs.map((documentDoc) => ({ id: documentDoc.id, ...documentDoc.data() }));
        commit('setDocuments', documents);
      } else {
        commit('setDocuments', []);
      }
    },
    async updateDocumentStatus({ commit }, { id, status }) {
      // Commit the mutation to update the local state
      commit('updateDocumentStatus', { id, status });

      // Update the Firebase document
      try {
        const { user } = this.state;
        if (user) {
          const documentRef = doc(db, 'users', user.uid, 'content', id);
          await updateDoc(documentRef, { status });
        }
      } catch (error) {
        console.error('Error updating document status:', error);
      }
    },
    async updateDocument({ commit }, { id, payload }) {
      commit('updateDocument', { id, payload });
      // Update the Firebase document
      try {
        const { user } = this.state;
        if (user) {
          const documentRef = doc(db, 'users', user.uid, 'content', id);
          await updateDoc(documentRef, payload);
        }
      } catch (error) {
        console.error('Error updating document status:', error);
      }
    },
    async fetchProducts({ commit }) {
      const products = await retrieveProducts();
      commit('setProducts', products);
    },
    async fetchSubscriptions({ commit }) {
      const subscriptions = await retrieveSubscriptions();
      commit('setSubscriptions', subscriptions);
    },
    async logOut({ commit }) {
      console.log('logOut action called');
      await auth.signOut();
      commit('RESET_STATE', null);
      return Promise.resolve();
    },
  },
  modules: {
  },
});

export default store;
