/* eslint-disable import/no-extraneous-dependencies */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import store from '../store';
import sharedRoutes from './shared';
import userRoutes from './user';
import adminRoutes from './admin';

const routes: Array<RouteRecordRaw> = [
  ...sharedRoutes,
  ...userRoutes,
  ...adminRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

let userLoaded = false;
let subscriptionsLoaded = false;

onAuthStateChanged(auth, async (user) => {
  store.dispatch('fetchProducts');
  if (user) {
    await store.dispatch('fetchUser', user);
    if (!userLoaded) {
      userLoaded = true;
      await store.dispatch('fetchKeywords', user);
      await store.dispatch('fetchDocuments', user);
      await store.dispatch('fetchSprints', user);
      if (!subscriptionsLoaded) {
        await store.dispatch('fetchSubscriptions');
        subscriptionsLoaded = true;
      }
    }
  } else {
    store.dispatch('fetchUser', null);
    // router.push('/');
  }
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);

  if (requiresAuth || requiresAdmin) {
    await new Promise<void>((resolve) => {
      if (userLoaded) {
        resolve();
      } else {
        onAuthStateChanged(auth, () => {
          resolve();
        });
      }
    });
  }

  const user = store.getters.currentUser;
  const { isAdmin } = store.getters;

  if (user && !subscriptionsLoaded) {
    await store.dispatch('fetchSubscriptions');
    subscriptionsLoaded = true;
  }

  // const subscriptions = store.getters.currentSubscriptions;
  // const hasSubscription = subscriptions.some((sub: any) => sub.status === 'active');

  if (user && to.path === '/login') {
    // Check if the user is trying to access the login page while already logged in.
    next('/dashboard');
  } else if (requiresAuth && !user) {
    // Check if the user needs authentication and is not logged in.
    next('/login');
  } else if (requiresAdmin && (!user || !isAdmin)) {
    // Check if the user needs admin access and is not an admin.
    next('/');
  } else if (user && to.path === '/') {
    // Check if the user is on the homepage while logged in.
    next('/dashboard');
  } else {
    // Allow navigation in all other cases.
    next();
  }
});

export default router;
